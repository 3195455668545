import React from "react";
import "./Login.css";

import { Navigate, useNavigate } from "react-router-dom";

import FFForm from "components/primitive/ff-form/FFForm";
import FFInput from "components/primitive/ff-input/FFInput";
import FFLink from "components/primitive/ff-link/FFLink";

import ApiService from "utils/api.service";
import { Auth } from "utils/ui-messages";
import { UserAuthInformation } from "utils/data-structures";
import { UserContext } from "utils/context.user";

interface LoginInterface {
  apiService: ApiService;
}

function Login({ apiService }: LoginInterface) {
  const navigate = useNavigate();
  const user = React.useContext(UserContext);

  const [ feedback, setFeedback ] = React.useState("");
  const [ isLoading, setIsLoading ] = React.useState(false);

  const [ password, setPassword ] = React.useState("");
  const [ username, setUsername ] = React.useState("");

  const handlePasswordChange = (ev: React.FormEvent<HTMLInputElement>) => setPassword(ev.currentTarget.value);
  const handleUsernameChange = (ev: React.FormEvent<HTMLInputElement>) => setUsername(ev.currentTarget.value);

  const handleSubmit = () => {
    if (username && username.trim().length < 1) {
      setFeedback("Username is empty.");
      return;
    }

    if (password && password.trim().length < 1) {
      setFeedback("Password is empty.");
      return;
    }

    if (!username || !password) {
      if (!username && password) {
        setFeedback(Auth["missing-username"]);
      }

      if (username && !password) {
        setFeedback(Auth["missing-password"]);
      }

      if (!username && !password) {
        setFeedback(Auth["missing-username-password"]);
      }

      return;
    }

    setIsLoading(true);
    setFeedback("");

    apiService.logIn(username, password)
      .then((auth: UserAuthInformation) => {
        setIsLoading(false);
        user.setAuth(auth);

        if (user.pastLocation && user.pastLocation !== "/login") {
          navigate(user.pastLocation);
        } else {
          navigate("/app");
        }
      })
      .catch((message: string) => {
        setFeedback(message || "Failed to login.");
        setIsLoading(false);
      });
  }

  if (apiService.isLoggedIn()) {
    return (<Navigate to="/app" />);
  }

  return (
    <div className="ff-login">
      <FFForm
        feedback={ feedback }
        hasCancel={ false }
        isLoading={ isLoading }
        submitLabel="Login"
        title="Access point"
        onSubmit={ handleSubmit }
      >
        <FFInput
          id="username"
          label="Username"
          value={ username }
          onChange={ handleUsernameChange } />
        <FFInput
          id="password"
          label="Password"
          type="password"
          value={ password }
          onChange={ handlePasswordChange } />
      </FFForm>
      <p>
        <FFLink href="/password-recovery">Password recovery</FFLink>
      </p>
    </div>
  );
}

export default Login;
