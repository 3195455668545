import React from "react";
import "./Footer.css";

function Footer() {
  return <div className="footer">
    <p><em>ff<span>:</span>dash</em> supporting <em>FilthyFingers</em></p>
    <p>v{ process.env.REACT_APP_VERSION }</p>
  </div>;
}

export default Footer;
