import React from "react";
import "./FFButton.css";

interface FFButtonInterface {
  className?: string;
  type?: "button" | "submit";
  children: React.ReactNode;
  onClick?: (ev: React.FormEvent<HTMLButtonElement>) => void;
}

function FFButton({ className = "", type = "button", children, onClick }: FFButtonInterface) {
  const handleClick = (ev: React.FormEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(ev);
    }
  }

  const classNames = [ "ff-button" ];

  if (className) {
    classNames.push(className);
  }

  return (
    <button className={ classNames.join(" ") } type={ type } onClick={ handleClick }>
      { children }
    </button>
  );
}

export default FFButton;
