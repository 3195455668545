import React from "react";
import "./FFLink.css";

import { Link } from "react-router-dom";

interface FFLinkInterface {
  children: React.ReactNode;
  href: string;
  isInternal?: boolean;
}

function FFLink({ children, href, isInternal = true }: FFLinkInterface) {
  const final = isInternal ? <Link className="link" to={href}>{children}</Link> : <a className="link" href={href}>{children}</a>;

  return final;
}

export default FFLink;
