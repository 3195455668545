import React from "react";
import "./RoadmapEpics.css";

import { useParams, useSearchParams } from "react-router-dom";

import ApiService from "utils/api.service";
import { UserContext } from "utils/context.user";

import {
  KanbanTaskDomain,
  KanbanTask,
  KanbanTaskModel,
  ProfileModel
} from "utils/data-structures";

import {
  isProperKanbanTaskModel,
  kanbanTaskModelsToBoardFormat,
} from "utils/data-utils";

import FFKanbanBoard from "components/smart/ff-kanban-board/FFKanbanBoard";
import FFLink from "components/primitive/ff-link/FFLink";
import FFMessage from "components/primitive/ff-message/FFMessage";
import FFModalCreateTask from "components/smart/ff-modal-create-task/FFModalCreateTask";
import FFModalEditTask from "components/smart/ff-modal-edit-task/FFModalEditTask";
import FFTitle from "components/primitive/ff-title/FFTitle";

interface RoadmapEpicsInterface {
  apiService: ApiService;
}

function RoadmapEpics({ apiService }: RoadmapEpicsInterface ) {
  const user = React.useContext(UserContext);
  const { roadmapId } = useParams();

  const [ safeId, setSafeId ] = React.useState(0);
  const [ roadmapShortTitle, setRoadmapShortTitle ] = React.useState("...");
  const [ urlTaskId, setUrlTaskId ] = useSearchParams();

  const [ feedback, setFeedback ] = React.useState("");
  const [ isLoading, setIsLoading ] = React.useState(true);
  const [ tasks, setTasks ] = React.useState<Array<KanbanTask>>([]);
  const [ kanbanDomains, setKanbanDomains ] = React.useState<Array<KanbanTaskDomain>>([]);
  const [ kanbanAssignees, setKanbanAssignees ] = React.useState<Array<ProfileModel>>([]);

  const [ createModalVisible, setCreateModalVisible ] = React.useState(false);
  const [ feedbackCreateTask, setFeedbackCreateTask ] = React.useState("");
  const [ isLoadingCreateTask, setIsLoadingCreateTask ] = React.useState(false);
  const [ activeCreateTask, setActiveCreateTask ] = React.useState<KanbanTaskModel | null>(null);

  const [ editModalVisible, setEditModalVisible ] = React.useState(false);
  const [ feedbackEditTask, setFeedbackEditTask ] = React.useState("");
  const [ isLoadingEditTask, setIsLoadingEditTask ] = React.useState(false);
  const [ activeEditTask, setActiveEditTask ] = React.useState<KanbanTaskModel | null>(null);

  const getData = React.useCallback(async (parentId: number) => {
    setFeedback("");
    setIsLoading(true);

    try {
      if (!kanbanAssignees.length) {
        const assigneesResponse = await apiService.getAdmins(0, 1000);
        setKanbanAssignees(assigneesResponse.data);      
      }

      if (!kanbanDomains.length) {
        const domainsResponse = await apiService.getDomains(0, 1000);
        setKanbanDomains(domainsResponse.data);
      }

      const tasksResponse = await apiService.getTasks(parentId);
      const boardData = kanbanTaskModelsToBoardFormat(tasksResponse.data, kanbanDomains, kanbanAssignees);
      setTasks(boardData);
    } catch (error: unknown) {
      setKanbanAssignees([]);
      setKanbanDomains([]);
      setTasks([]);
      setFeedback(typeof error === "string" ? error : "Unknown error.");
    }

    setIsLoading(false);
  }, [ apiService, kanbanDomains, kanbanAssignees ]);

  const handleCreateClick = () => {
    setActiveCreateTask({
      title: "",
      shortTitle: "",
      description: "",
      state: "To Define",
      type: "Epic",
      priority: 1,
      domainId: null,
      parentId: safeId,
      authorId: user.auth.id,
      assigneeId: null
    });

    setCreateModalVisible(true);
  }

  const handleCreateClose = () => {
    setCreateModalVisible(false);
  }

  const handleCreateSave = (data: KanbanTaskModel) => {
    const check = isProperKanbanTaskModel(data);

    if (check !== "OK") {
      setFeedbackCreateTask(check);
      return;
    }

    setIsLoadingCreateTask(true);

    apiService.createTask(data)
      .then(() => {
        setFeedbackCreateTask("");
        setIsLoadingCreateTask(false);
        setCreateModalVisible(false);
        setActiveCreateTask(null);
        safeId && getData(safeId);
      })
      .catch((error: any) => {
        setFeedbackCreateTask(error);
        setIsLoadingCreateTask(false);
      });
  }

  const handleTaskClick = React.useCallback((id: number) => {
    setUrlTaskId(new URLSearchParams({ taskId: id.toString() }));
    setFeedback("");

    apiService.getTask(id)
      .then(task => {
        setActiveEditTask(task);
        setEditModalVisible(true);
      })
      .catch((error: any) => {
        setFeedback(error);
      });
  }, [ apiService, setUrlTaskId ]);

  const handleEditClose = () => {
    setUrlTaskId(new URLSearchParams({}));
    setEditModalVisible(false);
    setActiveEditTask(null);
  }

  const handleEditSave = (data: KanbanTaskModel) => {
    const check = isProperKanbanTaskModel(data);

    if (check !== "OK") {
      setFeedbackEditTask(check);
      return;
    }

    setIsLoadingEditTask(true);

    apiService.updateTask(data)
      .then(() => {
        setFeedbackEditTask("");
        setIsLoadingEditTask(false);
        setEditModalVisible(false);
        setActiveEditTask(null);
        setUrlTaskId(new URLSearchParams({}));
        safeId && getData(safeId);
      })
      .catch((error: any) => {
        setFeedbackEditTask(error);
        setIsLoadingEditTask(false);
      });
  }

  const handleEditDelete = (id: number) => {
    setIsLoadingEditTask(true);

    apiService.deleteTask(id)
      .then(() => {
        setFeedbackEditTask("");
        setIsLoadingEditTask(false);
        setEditModalVisible(false);
        setActiveEditTask(null);
        setUrlTaskId(new URLSearchParams({}));
        safeId && getData(safeId);
      })
      .catch((error: any) => {
        setFeedbackEditTask(error);
        setIsLoadingEditTask(false);
      });
  }

  const message = feedback ? <FFMessage>{ feedback }</FFMessage> : (<></>);

  const roadmapLink = <FFLink href="/app/roadmap">Roadmap</FFLink>;

  // Check if task should be opened on load
  React.useEffect(() => {
    const taskId = urlTaskId.get("taskId");

    if (taskId && taskId.length > 0) {
      const safeTaskId = parseInt(taskId, 10);

      if (isNaN(safeTaskId)) {
        setUrlTaskId(new URLSearchParams({}));
      } else {
        handleTaskClick(safeTaskId);
      }
    }
  }, [ handleTaskClick, setUrlTaskId, urlTaskId ]);

  React.useEffect(() => {
    if (!roadmapId || isNaN(parseInt(roadmapId, 10))) {
      setFeedback("Roadmap ID is missing. Something smells fishy.");
      return;
    }

    const id = parseInt(roadmapId, 10);
    setSafeId(id);

    apiService.getTask(id)
      .then(task => {
        setRoadmapShortTitle(task.shortTitle);
      })
      .catch((error: any) => {
        setFeedback("Could not get pretty name of the roadmap.");
      });

    getData(id);
  }, [ apiService, getData, roadmapId ] );

  return (<>
    <FFTitle>{ roadmapLink } / { roadmapShortTitle }</FFTitle>
    { message }
    <FFKanbanBoard
      isLoading={ isLoading }
      tasks={ tasks }
      onCreateClick={ handleCreateClick }
      onTaskClick={ handleTaskClick }
    />
    { activeCreateTask &&
      <FFModalCreateTask
        feedback={ feedbackCreateTask }
        isLoading={ isLoadingCreateTask }
        modalTitle="Create task"
        task={ activeCreateTask }
        visible={ createModalVisible }
        domains={ kanbanDomains }
        assignees={ kanbanAssignees }
        prettyParent={ roadmapShortTitle }
        onClose={ handleCreateClose }
        onSave={ handleCreateSave }
      />
    }
    { activeEditTask &&
      <FFModalEditTask
        feedback={ feedbackEditTask }
        isLoading={ isLoadingEditTask }
        modalTitle="Edit task"
        task={ activeEditTask }
        visible={ editModalVisible }
        domains={ kanbanDomains }
        assignees={ kanbanAssignees }
        prettyParent={ roadmapShortTitle }
        onClose={ handleEditClose }
        onSave={ handleEditSave }
        onDelete={ handleEditDelete }
      />
    }
  </>);
}

export default RoadmapEpics;
