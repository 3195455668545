import React from "react";
import "./FFSelect.css";

import { FFSelectOptionInterface } from "utils/data-structures";

interface FFSelectInterface {
  id: string;
  disabled?: boolean;
  label: string;
  options: Array<FFSelectOptionInterface>;
  value?: string;
  defaultName?: string;
  onChange?: (value: string) => void;
}

function FFSelect({
  id,
  disabled = false,
  label,
  options,
  value = "",
  defaultName = "",
  onChange
}: FFSelectInterface) {
  const [ isListVisible, setIsListVisible ] = React.useState(false);
  const [ activeValue, setActiveValue ] = React.useState(value);
  const [ activeName, setActiveName ] = React.useState(defaultName);

  const handleListToggle = (ev: React.FormEvent<HTMLButtonElement>) => {
    setIsListVisible(!isListVisible);
  }

  const handleChange = (ev: React.FormEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    const value = ev.currentTarget.getAttribute("data-value");
    const name  = ev.currentTarget.getAttribute("data-name" );

    if (value && name) {
      setActiveName(name)
      setActiveValue(value);

      if (onChange) {
        onChange(value);
      } 
    }
  }

  const renderOptions: Array<React.ReactElement<HTMLOptionElement>> = [];

  options.forEach(
    (option, i) => renderOptions.push(
      <li key={ i }>
        <button
          className={ option.value === activeValue ? "selected" : "" }
          onClick={ handleChange }
          data-value={ option.value }
          data-name={ option.name }
        >{ option.name }</button>
      </li>));

  React.useEffect(() => {
    setActiveName(defaultName);
  }, [ defaultName ]);

  return <div className="ff-select">
    <label>{ label }</label>
    <button
      type="button"
      className="ff-select-trigger"
      disabled={ disabled }
      onClick={ handleListToggle }
    >{ activeName || activeValue || "None" }</button>
    { isListVisible && <ul className="ff-select-options">
      { renderOptions }
    </ul> }
  </div>;
}

export default FFSelect;
