import React from "react";
import "./Domains.css";

import { useNavigate } from "react-router-dom";

import FFMessage from "components/primitive/ff-message/FFMessage";
import FFSmartTable from "components/smart/ff-smart-table/FFSmartTable";

import ApiService from "utils/api.service";
import { defaultPageSize } from "configuration";
import { KanbanTaskDomain } from "utils/data-structures";

interface DomainsInterface {
  apiService: ApiService;
}

function Domains({ apiService }: DomainsInterface) {
  const navigate = useNavigate();

  const [ feedback, setFeedback ] = React.useState("");
  const [ isTableLoading, setIsTableLoading ] = React.useState(true);
  const [ tableData, setTableData ] = React.useState<Array<KanbanTaskDomain>>([]);
  const [ tableTotal, setTableTotal ] = React.useState(0);

  const tableOptions = {
    shouldCreate: true,
    shouldDelete: true,
    shouldUpdate: true,
    keyProp: "id"
  }

  const columns = [
    {
      name: "ID",
      key: "id",
      width: 15
    }, {
      name: "Name",
      key: "name",
      width: 45
    }, {
      name: "Color",
      key: "color",
      width: 40
    }
  ];

  const getData = React.useCallback((offset: number, limit: number) => {
    setIsTableLoading(true);
    setFeedback("");

    apiService.getDomains(offset, limit)
      .then(response => {
        setTableTotal(response.total);
        setTableData(response.data);
        setIsTableLoading(false);
      })
      .catch(error => {
        setIsTableLoading(false);
        setFeedback(error);
        setTableData([]);
      });
  }, [ apiService ]);

  const handleCreate = () => {
    navigate("/app/domains/create");
  }

  const handleUpdate = (id: string) => {
    navigate("/app/domains/update/" + id);
  }

  const handleDelete = (id: string) => {
    navigate("/app/domains/delete/" + id);
  }

  const handlePrevPage = (offset: number, limit: number) => getData(offset, limit);
  const handleNextPage = (offset: number, limit: number) => getData(offset, limit);
  const handleFirstPage = (offset: number, limit: number) => getData(offset, limit);
  const handleLastPage = (offset: number, limit: number) => getData(offset, limit);

  const message = feedback ? <FFMessage>{ feedback }</FFMessage> : (<></>);

  React.useEffect(() => {
    getData(0, defaultPageSize);
  }, [ getData ]);

  return <section>
    { message }
    <FFSmartTable
      tableName="Domains"
      options={tableOptions}
      columns={columns}
      data={tableData}
      total={tableTotal}
      isLoading={isTableLoading}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
      onPrevPage={handlePrevPage}
      onNextPage={handleNextPage}
      onFirstPage={handleFirstPage}
      onLastPage={handleLastPage}
    />
  </section>;
}

export default Domains;
