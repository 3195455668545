import React from "react";
import "./Home.css";

function Home() {
  return (
    <div>
      <ol>
        <li>Roadmap is where tasks are.</li>
        <li>Domains are task categories.</li>
        <li>Admins work on this project.</li>
      </ol>
    </div>
  );
}

export default Home;
