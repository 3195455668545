import React from "react";
import "./FFForm.css";

import FFButton from "components/primitive/ff-button/FFButton";
import FFLoading from "components/primitive/ff-loading/FFLoading";
import FFMessage from "components/primitive/ff-message/FFMessage";
import FFTitle from "components/primitive/ff-title/FFTitle";

interface FFFormInterface {
  children: React.ReactNode;
  feedback: string;
  hasCancel: boolean;
  isLoading: boolean;
  submitLabel: string;
  title: string | React.ReactNode;
  onCancel?: () => void;
  onSubmit?: () => void;
}

function FFForm({
  children,
  feedback,
  hasCancel,
  isLoading,
  submitLabel,
  title,
  onCancel,
  onSubmit
}: FFFormInterface) {
  const handleCancel = (ev: React.FormEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    onCancel && onCancel();
  }

  const handleSubmit = (ev: React.MouseEvent<HTMLFormElement>) => {
    ev.preventDefault();
    onSubmit && onSubmit();
  }

  const message = feedback ? <FFMessage>{ feedback }</FFMessage> : <></>;
  const footer = isLoading ? <FFLoading /> : <>
    <FFButton type="submit">{ submitLabel }</FFButton>
    { hasCancel ? <FFButton onClick={ handleCancel }>Cancel</FFButton> : <></> }
  </>;

  return <form className="ff-form" onSubmit={ handleSubmit }>
    <FFTitle>{ title }</FFTitle>
    { message }
    <div>
      { children }
    </div>
    <footer>
      { footer }
    </footer>
  </form>;
}

export default FFForm;
