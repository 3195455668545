import React from "react";
import "./FFKanbanBoard.css";

import { KanbanTask } from "utils/data-structures";

import FFButton from "components/primitive/ff-button/FFButton";
import FFKanbanTask from "components/smart/ff-kanban-task/FFKanbanTask";
import FFLoading from "components/primitive/ff-loading/FFLoading";

interface FFKanbanBoardInterface {
  isLoading?: boolean;
  tasks: Array<KanbanTask>;
  onCreateClick: () => void;
  onTaskClick: (id: number) => void;
}

function FFKanbanBoard({
  isLoading = false,
  tasks,
  onCreateClick,
  onTaskClick
}: FFKanbanBoardInterface) {
  if (isLoading) {
    return <FFLoading />;
  }

  const handleCreateClick = () => onCreateClick();
  const handleTaskClick = (id: number) => onTaskClick(id);

  const toDefine: Array<React.ReactElement<typeof FFKanbanTask>> = [];
  const toDo: Array<React.ReactElement<typeof FFKanbanTask>> = [];
  const inProgress: Array<React.ReactElement<typeof FFKanbanTask>> = [];
  const inReview: Array<React.ReactElement<typeof FFKanbanTask>> = [];
  const done: Array<React.ReactElement<typeof FFKanbanTask>> = [];

  tasks
    .filter(el => el.state === "To Define" )
    .forEach(
      el => toDefine.push(
        <li key={ `task-${ el.id }` }>
          <FFKanbanTask task={ el } onTaskClick={ handleTaskClick } />
        </li>));

  tasks
    .filter(el => el.state === "To Do" )
    .forEach(
      el => toDo.push(
        <li key={ `task-${ el.id }` }>
          <FFKanbanTask task={ el } onTaskClick={ handleTaskClick } />
        </li>));

  tasks
    .filter(el => el.state === "In Progress" )
    .forEach(
      el => inProgress.push(
        <li key={ `task-${ el.id }` }>
          <FFKanbanTask task={ el } onTaskClick={ handleTaskClick } />
        </li>));

  tasks
    .filter(el => el.state === "In Review" )
    .forEach(
      el => inReview.push(
        <li key={ `task-${ el.id }` }>
          <FFKanbanTask task={ el } onTaskClick={ handleTaskClick } />
        </li>));

  tasks
    .filter(el => el.state === "Done" )
    .forEach(
      el => done.push(
        <li key={ `task-${ el.id }` }>
          <FFKanbanTask task={ el } onTaskClick={ handleTaskClick } />
        </li>));

  return <article className="ff-kanban-board">
    <FFButton onClick={ handleCreateClick }>Create</FFButton>
    <section>
      <h3>To Define</h3>
      <ul>{ toDefine }</ul>
    </section>
    <section>
      <h3>To Do</h3>
      <ul>{ toDo }</ul>
    </section>
    <section>
      <h3>In Progress</h3>
      <ul>{ inProgress }</ul>
    </section>
    <section>
      <h3>In Review</h3>
      <ul>{ inReview }</ul>
    </section>
    <section>
      <h3>Done</h3>
      <ul>{ done }</ul>
    </section>
  </article>;
}

export default FFKanbanBoard;
