import React from "react";
import "./FFLoading.css";

function FFLoading() {
  return (
    <div className="ff-loading">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default FFLoading;
