import axios from "axios";

import {
  AdminModelCreate,
  AdminModelUpdate,
  GetAllResponse,
  KanbanTaskDomain,
  KanbanTaskDomainCreate,
  ProfileModel,
  ProfileModelUpdate,
  UserAuthInformation,
  KanbanTaskModel
} from "utils/data-structures";

export default class ApiService {
  private accessToken: string | null = null;
  private base: string;

  constructor(base: string) {
    this.base = base;
  }

  private getProtectedConfig() {
    return {
      headers: {
        "x-access-token": this.accessToken
      }
    }
  }

  logIn(username: string, password: string): Promise<UserAuthInformation> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ this.base }/v1/auth/login`, { username, password })
        .then(response => {
          this.accessToken = response.data.data.tokenAccess;
          resolve(response.data.data);
        })
        .catch(error => reject(error.response?.data?.data || "There was an error."));
    });
  }

  logOut() {
    const accessToken = this.accessToken;
    this.accessToken = null;

    axios
      .post(`${ this.base }/v1/auth/logout`, {}, {
        headers: {
          "x-access-token": accessToken
        }
      })
      .then(response => { /* Do nothing */ })
      .catch(error => { /* Do nothing */ });
  }

  isLoggedIn(): boolean {
    return this.accessToken !== null;
  }

  getProfile(): Promise<ProfileModel> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ this.base }/v1/profile`, this.getProtectedConfig())
        .then(response => resolve(response.data.data))
        .catch(error => reject(error.response?.data?.data || "There was a network error."));
    });
  }

  updateProfile(profile: ProfileModelUpdate): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${ this.base }/v1/profile`, profile, this.getProtectedConfig())
        .then(() => resolve())
        .catch(error => reject(error.response?.data?.data || "There was a network error."));
    });
  }

  getDomains(offset: number, limit: number): Promise<GetAllResponse<KanbanTaskDomain>> {
    return new Promise((resolve, reject) => {
      const config = {
        params: {
          offset,
          limit
        },
        ...this.getProtectedConfig()
      }

      axios
        .get(`${ this.base }/v1/domains`, config)
        .then(response => {
          resolve({
            total: parseInt(response.headers["x-total-count"] || "", 10),
            data: response.data.data
          });
        })
        .catch(error => reject(error.response?.data?.data || "There was a network error."));
    });
  }

  getDomain(id: number): Promise<KanbanTaskDomain> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ this.base }/v1/domains/${ id }`, this.getProtectedConfig())
        .then(response => resolve(response.data.data))
        .catch(error => reject(error.response?.data?.data || "There was a network error."));
    });
  }

  createDomain(domain: KanbanTaskDomainCreate): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ this.base }/v1/domains`, domain, this.getProtectedConfig())
        .then(() => resolve())
        .catch(error => reject(error.response?.data?.data || "There was a network error."));
    });
  }

  updateDomain(domain: KanbanTaskDomain): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${ this.base }/v1/domains/${ domain.id }`, domain, this.getProtectedConfig())
        .then(() => resolve())
        .catch(error => reject(error.response?.data?.data || "There was a network error."));
    });
  }

  deleteDomain(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${ this.base }/v1/domains/${ id }`, this.getProtectedConfig())
        .then(() => resolve())
        .catch(error => reject(error.response?.data?.data || "There was a network error."));
    });
  }

  getAdmins(offset: number, limit: number): Promise<GetAllResponse<ProfileModel>> {
    return new Promise((resolve, reject) => {
      const config = {
        params: {
          offset,
          limit
        },
        ...this.getProtectedConfig()
      }

      axios
        .get(`${ this.base }/v1/admins`, config)
        .then(response => {
          resolve({
            total: parseInt(response.headers["x-total-count"] || "", 10),
            data: response.data.data
          });
        })
        .catch(error => reject(error.response?.data?.data || "There was a network error."));
    });
  }

  getAdmin(id: number): Promise<ProfileModel> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ this.base }/v1/admins/${ id }`, this.getProtectedConfig())
        .then(response => resolve(response.data.data))
        .catch(error => reject(error.response?.data?.data || "There was a network error."));
    });
  }

  createAdmin(admin: AdminModelCreate): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ this.base }/v1/admins`, admin, this.getProtectedConfig())
        .then(() => resolve())
        .catch(error => reject(error.response?.data?.data || "There was a network error."));
    });
  }

  updateAdmin(admin: AdminModelUpdate): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${ this.base }/v1/admins/${ admin.id }`, admin, this.getProtectedConfig())
        .then(() => resolve())
        .catch(error => reject(error.response?.data?.data || "There was a network error."));
    });
  }

  deleteAdmin(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${ this.base }/v1/admins/${ id }`, this.getProtectedConfig())
        .then(() => resolve())
        .catch(error => reject(error.response?.data?.data || "There was a network error."));
    });
  }

  getTasks(parentId: number | null): Promise<GetAllResponse<KanbanTaskModel>> {
    return new Promise((resolve, reject) => {
      const config = {
        params: {
          parentId
        },
        ...this.getProtectedConfig()
      }

      axios
        .get(`${ this.base }/v1/tasks`, config)
        .then(response => {
          resolve({
            total: parseInt(response.headers["x-total-count"] || "", 10),
            data: response.data.data
          });
        })
        .catch(error => reject(error.response?.data?.data || "There was a network error."));
    });
  }

  getTask(id: number): Promise<KanbanTaskModel> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ this.base }/v1/tasks/${ id }`, this.getProtectedConfig())
        .then(response => resolve(response.data.data))
        .catch(error => reject(error.response?.data?.data || "There was a network error."));
    });
  }

  createTask(task: KanbanTaskModel): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ this.base }/v1/tasks`, task, this.getProtectedConfig())
        .then(() => resolve())
        .catch(error => reject(error.response?.data?.data || "There was a network error."));
    });
  }

  updateTask(task: KanbanTaskModel): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!task.id) {
        reject("Missing task ID.");
        return;
      }

      axios
        .put(`${ this.base }/v1/tasks/${ task.id }`, task, this.getProtectedConfig())
        .then(() => resolve())
        .catch(error => reject(error.response?.data?.data || "There was a network error."));
    });
  }

  deleteTask(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${ this.base }/v1/tasks/${ id }`, this.getProtectedConfig())
        .then(() => resolve())
        .catch(error => reject(error.response?.data?.data || "There was a network error."));
    });
  }
}
