import React from "react";
import "./FFInput.css";

interface FFInputInterface {
  id: string;
  disabled?: boolean;
  label: string;
  type?: "text" | "password" | "number";
  value?: string;
  onChange?: (ev: React.FormEvent<HTMLInputElement>) => void;
}

function FFInput({
  id,
  disabled = false,
  label,
  type = "text",
  value = "",
  onChange
}: FFInputInterface) {
  const handleChange = (ev: React.FormEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(ev);
    }
  }

  return (
    <div className="ff-input">
      <label htmlFor={ id }>{ label }</label>
      <input
        disabled={ disabled }
        id={ id }
        type={ type }
        value={ value }
        onChange={ handleChange }
      />
    </div>
  );
}

export default FFInput;
