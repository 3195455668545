import React from "react";
import "./FFWebsiteTitle.css";

function FFWebsiteTitle() {
  return (
    <h1 className="title">
      ff<em>:</em>dash
    </h1>
  );
}

export default FFWebsiteTitle;
