import React from "react";

import { UserAuthInformation } from "utils/data-structures";

export interface UserContextInterface {
  auth: UserAuthInformation;
  setAuth: (auth: UserAuthInformation) => void;
  pastLocation: string;
  setPastLocation: (pastLocation: string) => void;
}

export const DefaultUserContext: UserContextInterface = {
  auth: {
    tokenAccess: "",
    id: -1,
    username: "",
    isSuperuser: false
  },
  setAuth: (auth: UserAuthInformation) => {},
  pastLocation: "",
  setPastLocation: (pastLocation: string) => {}
}

export const UserContext = React.createContext(DefaultUserContext);

export const UserProvider = UserContext.Provider;
