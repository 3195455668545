import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

import "./App.css";

import Header from "components/layout/header/Header";
import Sidebar from "components/layout/sidebar/Sidebar";
import Footer from "components/layout/footer/Footer";

import FFButton from "components/primitive/ff-button/FFButton";

import ApiService from "utils/api.service";
import { UserContext } from "utils/context.user";

interface AppInterface {
  apiService: ApiService;
}

function App({ apiService }: AppInterface) {
  const navigate = useNavigate();
  const user = React.useContext(UserContext);

  const [ isSidebarVisible, setIsSidebarVisible ] = React.useState(window.innerWidth > 640 ? true : false);

  const handleResize = (ev: Event) => {
    if (!isSidebarVisible && window.innerWidth > 640) {
      setIsSidebarVisible(true);
    }

    if (isSidebarVisible && window.innerWidth <= 640) {
      setIsSidebarVisible(false);
    }
  }

  const handleToggleSidebar = (ev: React.FormEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    setIsSidebarVisible(!isSidebarVisible);
  };

  window.addEventListener("resize", handleResize);

  React.useEffect(() => {
    if (!apiService.isLoggedIn()) {
      user.setPastLocation(window.location.pathname + window.location.search);
      navigate("/login");
    }
  }, [ apiService, user, navigate ]);

  return (
    <div className="container">
      <Header apiService={ apiService } />
      <div className="content">
        <FFButton className="sidebar-menu" onClick={ handleToggleSidebar }>Menu</FFButton>
        <Sidebar isVisible={ isSidebarVisible } />
        <div className="outlet">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
