import React from "react";
import "./ForgotPassword.css";

import FFLink from "components/primitive/ff-link/FFLink";
import FFWebsiteTitle from "components/primitive/ff-website-title/FFWebsiteTitle";

function ForgotPassword() {
  return <div className="forgot-password">
    <FFWebsiteTitle />
    <p>Contact humans.</p>
    <FFLink href="/login">Login</FFLink>
  </div>;
}

export default ForgotPassword;
