import React from "react";
import "./FFMessage.css";

interface FFMessageInterface {
  children: React.ReactNode;
}

function FFMessage({ children }: FFMessageInterface) {
  return (
    <div className="ff-message">
      {children}
    </div>
  );
}

export default FFMessage;
