import React from "react";
import "./Header.css";

import { useNavigate } from "react-router-dom";

import FFButton from "components/primitive/ff-button/FFButton";
import FFLink from "components/primitive/ff-link/FFLink";
import FFWebsiteTitle from "components/primitive/ff-website-title/FFWebsiteTitle";

import ApiService from "utils/api.service";
import { DefaultUserContext, UserContext } from "utils/context.user";

interface HeaderInterface {
  apiService: ApiService;
}

function Header({ apiService }: HeaderInterface ) {
  const navigate = useNavigate();
  const user = React.useContext(UserContext);

  const handleLogout = async (ev: React.FormEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    apiService.logOut();
    user.setAuth(DefaultUserContext.auth);
    navigate("/login");
  }

  return (
    <header className="header">
      <FFWebsiteTitle />
      <div className="user-info">
        <p>
          <FFLink href="/app/profile">{ user.auth.username }</FFLink>&nbsp;
          <FFButton onClick={ handleLogout }>Logout</FFButton>
        </p>
      </div>
    </header>
  );
}

export default Header;
