import React from "react";
import "./AdminsCreate.css";

import { useNavigate } from "react-router-dom";

import FFCheckbox from "components/primitive/ff-checkbox/FFCheckbox";
import FFForm from "components/primitive/ff-form/FFForm";
import FFInput from "components/primitive/ff-input/FFInput";
import FFLink from "components/primitive/ff-link/FFLink";

import ApiService from "utils/api.service";

interface AdminsCreateInterface {
  apiService: ApiService;
}

function AdminsCreate({ apiService }: AdminsCreateInterface ) {
  const navigate = useNavigate();

  const [ feedback, setFeedback ] = React.useState("");
  const [ isLoading, setIsLoading ] = React.useState(false);

  const [ username, setUsername ] = React.useState("");
  const [ password, setPassword ] = React.useState("");
  const [ email, setEmail ] = React.useState("");
  const [ isSuperuser, setIsSuperuser ] = React.useState(false);

  const handleUsernameChange = (ev: React.FormEvent<HTMLInputElement>) => setUsername(ev.currentTarget.value);
  const handlePasswordChange = (ev: React.FormEvent<HTMLInputElement>) => setPassword(ev.currentTarget.value);
  const handleEmailChange = (ev: React.FormEvent<HTMLInputElement>) => setEmail(ev.currentTarget.value);
  const handleIsSuperuserChange = (checked: boolean) => setIsSuperuser(checked);

  const handleCancel = () => {
    navigate("/app/admins");
  }

  const handleSubmit = () => {
    if (!username || username.trim().length < 1) {
      setFeedback("Enter username of the new admin.");
      return;
    }

    if (!password || password.trim().length < 1) {
      setFeedback("Enter password of the new admin.");
      return;
    }

    if (!email || email.trim().length < 1) {
      setFeedback("Enter email of the new admin.");
      return;
    }

    setIsLoading(true);
    setFeedback("");

    const payload = { username, password, email, isSuperuser };

    apiService.createAdmin(payload)
      .then(() => {
        navigate("/app/admins");
      })
      .catch((error: any) => {
        setIsLoading(false);
        setFeedback(error);
      });
  }

  const title = <><FFLink href="/app/admins">Admins</FFLink> / Create</>;

  return (
    <FFForm
      feedback={ feedback }
      hasCancel={ true }
      isLoading={ isLoading }
      submitLabel="Create"
      title={ title }
      onCancel={ handleCancel }
      onSubmit={ handleSubmit }
    >
      <FFInput
        id="username"
        label="Username"
        value={ username }
        onChange={ handleUsernameChange }
      />
      <FFInput
        id="password"
        label="Password"
        value={ password }
        onChange={ handlePasswordChange }
      />
      <FFInput
        id="email"
        label="Email"
        value={ email }
        onChange={ handleEmailChange }
      />
      <FFCheckbox
        id="is-superuser"
        label="Is superuser"
        checked={ isSuperuser }
        onChange={ handleIsSuperuserChange }
      />
    </FFForm>
  );
}

export default AdminsCreate;
