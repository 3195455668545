import React from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import "./Root.css";

import Admins from "components/view/admins/Admins";
import AdminsCreate from "components/view/admins/create/AdminsCreate";
import AdminsDelete from "components/view/admins/delete/AdminsDelete";
import AdminsUpdate from "components/view/admins/update/AdminsUpdate";
import App from "components/composite/app/App";
import Domains from "components/view/domains/Domains";
import DomainsCreate from "components/view/domains/create/DomainsCreate";
import DomainsDelete from "components/view/domains/delete/DomainsDelete";
import DomainsUpdate from "components/view/domains/update/DomainsUpdate";
import ForgotPassword from "components/view/forgot-password/ForgotPassword";
import Home from "components/view/home/Home";
import Login from "components/view/login/Login";
import NotFound from "components/view/not-found/NotFound";
import Profile from "components/view/profile/Profile";
import Roadmap from "components/view/roadmap/Roadmap";
import RoadmapEpics from "components/view/roadmap-epics/RoadmapEpics";
import RoadmapStories from "components/view/roadmap-stories/RoadmapStories";

import ApiService from "utils/api.service";
import { DefaultUserContext, UserProvider } from "utils/context.user";

interface RootInterface {
  apiService: ApiService;
}

function Root({ apiService }: RootInterface) {
  const [ userContextAuth, setUserContextAuth ] = React.useState(DefaultUserContext.auth);
  const [ userContextPastLocation, setUserContextPastLocation ] = React.useState(DefaultUserContext.pastLocation);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navigate to="app" replace />,
    },
    {
      path: "login",
      element: <Login apiService={ apiService } />,
    },
    {
      path: "password-recovery",
      element: <ForgotPassword />
    },
    {
      path: "app",
      element: <App apiService={ apiService } />,
      children: [
        {
          path: "",
          element: <Home />,
        },

        // Business
        {
          path: "roadmap/:roadmapId/epic/:epicId",
          element: <RoadmapStories apiService={ apiService } />,
        },
        {
          path: "roadmap/:roadmapId",
          element: <RoadmapEpics apiService={ apiService } />,
        },
        {
          path: "roadmap",
          element: <Roadmap apiService={ apiService } />,
        },
        {
          path: "domains",
          element: <Domains apiService={ apiService } />,
        },
        {
          path: "domains/create",
          element: <DomainsCreate apiService={ apiService } />
        },
        {
          path: "domains/update/:elementId",
          element: <DomainsUpdate apiService={ apiService } />
        },
        {
          path: "domains/delete/:elementId",
          element: <DomainsDelete apiService={ apiService } />
        },
        {
          path: "admins",
          element: <Admins apiService={ apiService } />
        },
        {
          path: "admins/create",
          element: <AdminsCreate apiService={ apiService } />
        },
        {
          path: "admins/update/:elementId",
          element: <AdminsUpdate apiService={ apiService } />
        },
        {
          path: "admins/delete/:elementId",
          element: <AdminsDelete apiService={ apiService } />
        },
        {
          path: "profile",
          element: <Profile apiService={ apiService } />
        }
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return (
    <UserProvider value={{
      auth: userContextAuth,
      setAuth: setUserContextAuth,
      pastLocation: userContextPastLocation,
      setPastLocation: setUserContextPastLocation
    }}>
      <RouterProvider router={router} />
    </UserProvider>
  );
}

export default Root;
