import React from "react";
import "./Admins.css";

import { useNavigate } from "react-router-dom";

import FFMessage from "components/primitive/ff-message/FFMessage";
import FFSmartTable from "components/smart/ff-smart-table/FFSmartTable";

import ApiService from "utils/api.service";
import { defaultPageSize } from "configuration";
import { ProfileModel } from "utils/data-structures";
import { UserContext } from "utils/context.user";

interface AdminsInterface {
  apiService: ApiService;
}

function Admins({ apiService }: AdminsInterface) {
  const navigate = useNavigate();
  const user = React.useContext(UserContext);

  const [ feedback, setFeedback ] = React.useState("");
  const [ isTableLoading, setIsTableLoading ] = React.useState(true);
  const [ tableData, setTableData ] = React.useState<Array<ProfileModel>>([]);
  const [ tableTotal, setTableTotal ] = React.useState(0);

  const tableOptions = {
    shouldCreate: user.auth.isSuperuser,
    shouldDelete: user.auth.isSuperuser,
    shouldUpdate: user.auth.isSuperuser,
    keyProp: "id"
  }

  const columns = [
    {
      name: "ID",
      key: "id",
      width: 10
    }, {
      name: "Username",
      key: "username",
      width: 20
    }, {
      name: "Email",
      key: "email",
      width: 30
    }, {
      name: "Superuser",
      key: "isSuperuser",
      width: 15
    }, {
      name: "Last login",
      key: "lastLogin",
      width: 25
    }
  ];

  const getData = React.useCallback((offset: number, limit: number) => {
    setIsTableLoading(true);
    setFeedback("");

    apiService.getAdmins(offset, limit)
      .then(response => {
        setTableTotal(response.total);
        setTableData(response.data);
        setIsTableLoading(false);
      })
      .catch(error => {
        setIsTableLoading(false);
        setFeedback(error);
        setTableData([]);
      });
  }, [ apiService ]);

  const handleCreate = () => {
    navigate("/app/admins/create");
  }

  const handleUpdate = (id: string) => {
    navigate("/app/admins/update/" + id);
  }

  const handleDelete = (id: string) => {
    navigate("/app/admins/delete/" + id);
  }

  const handlePrevPage = (offset: number, limit: number) => getData(offset, limit);
  const handleNextPage = (offset: number, limit: number) => getData(offset, limit);
  const handleFirstPage = (offset: number, limit: number) => getData(offset, limit);
  const handleLastPage = (offset: number, limit: number) => getData(offset, limit);

  const message = feedback ? <FFMessage>{ feedback }</FFMessage> : (<></>);

  React.useEffect(() => {
    getData(0, defaultPageSize);
  }, [ getData ]);

  return <section>
    { message }
    <FFSmartTable
      tableName="Admins"
      options={tableOptions}
      columns={columns}
      data={tableData}
      total={tableTotal}
      isLoading={isTableLoading}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
      onPrevPage={handlePrevPage}
      onNextPage={handleNextPage}
      onFirstPage={handleFirstPage}
      onLastPage={handleLastPage}
    />
  </section>;
}

export default Admins;
