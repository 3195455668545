import React from "react";
import "./FFTitle.css";

interface FFTitleInterface {
  children: React.ReactNode;
}

function FFTitle({ children }: FFTitleInterface) {
  return <h2 className="ff-title">{children}</h2>;
}

export default FFTitle;
