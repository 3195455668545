import React from "react";
import "./Profile.css";

import { ProfileModelUpdate } from "utils/data-structures";

import FFCheckbox from "components/primitive/ff-checkbox/FFCheckbox";
import FFForm from "components/primitive/ff-form/FFForm";
import FFInput from "components/primitive/ff-input/FFInput";

import ApiService from "utils/api.service";
import { getPrettyDate } from "utils/data-utils";
import { UserContext } from "utils/context.user";

interface ProfileInterface {
  apiService: ApiService;
}

function Profile({ apiService }: ProfileInterface) {
  const user = React.useContext(UserContext);

  const [ feedback, setFeedback ] = React.useState("");
  const [ isLoading, setIsLoading ] = React.useState(true);

  const [ id, setId ] = React.useState("");
  const [ username, setUsername ] = React.useState("");
  const [ email, setEmail ] = React.useState("");
  const [ isSuperuser, setIsSuperuser ] = React.useState(false);
  const [ createdAt, setCreatedAt ] = React.useState("");
  const [ lastLogin, setLastLogin ] = React.useState("");
  const [ newPassword, setNewPassword ] = React.useState("");
  const [ repeatNewPassword, setRepeatNewPassword ] = React.useState("");
  const [ oldPassword, setOldPassword ] = React.useState("");

  const handleUsernameChange = (ev: React.FormEvent<HTMLInputElement>) => setUsername(ev.currentTarget.value);
  const handleEmailChange = (ev: React.FormEvent<HTMLInputElement>) => setEmail(ev.currentTarget.value);
  const handleNewPasswordChange = (ev: React.FormEvent<HTMLInputElement>) => setNewPassword(ev.currentTarget.value);
  const handleRepeatNewPasswordChange = (ev: React.FormEvent<HTMLInputElement>) => setRepeatNewPassword(ev.currentTarget.value);
  const handleOldPasswordChange = (ev: React.FormEvent<HTMLInputElement>) => setOldPassword(ev.currentTarget.value);

  const handleSubmit = () => {
    if (!oldPassword || oldPassword.trim().length < 1) {
      setFeedback("Enter old password to save changes.");
      return;
    }

    if (!username || username.trim().length < 1) {
      setFeedback("Username cannot be empty.");
      return;
    }

    if (username.trim().length < 6) {
      setFeedback("Username must have at least 6 characters.");
      return;
    }

    if (!email || email.trim().length < 1) {
      setFeedback("Email cannot be empty.");
      return;
    }

    if (newPassword && newPassword.trim().length < 1) {
      setFeedback("New password cannot contain blanks.");
      return;
    }

    if (newPassword && !repeatNewPassword) {
      setFeedback("Repeat new password to proceed with password change.");
      return;
    }

    if (newPassword && newPassword !== repeatNewPassword) {
      setFeedback("Please repeat new password twice, correctly.");
      return;
    }

    setIsLoading(true);
    setFeedback("");

    const payload: ProfileModelUpdate = {
      currentPassword: oldPassword,
      username,
      email
    };

    if (newPassword) {
      payload.newPassword = newPassword;
    }

    apiService.updateProfile(payload)
      .then(() => {
        setIsLoading(false);
        setFeedback("Update successful.");

        setNewPassword("");
        setRepeatNewPassword("");
        setOldPassword("");

        user.setAuth({ ...user.auth, username });
      })
      .catch(error => {
        setIsLoading(false);
        setFeedback(error);
      });
  }

  React.useEffect(() => {
    apiService.getProfile()
      .then(profile => {
        setIsLoading(false);
        setFeedback("");

        setId(profile.id.toString());
        setUsername(profile.username);
        setEmail(profile.email);
        setIsSuperuser(profile.isSuperuser);
        setCreatedAt(getPrettyDate(profile.createdAt));
        setLastLogin(getPrettyDate(profile.lastLogin));
      })
      .catch(error => {
        setIsLoading(false);
        setFeedback(error);
      });
  }, [ apiService ]);

  return (
    <FFForm
      feedback={ feedback }
      hasCancel={ false }
      isLoading={ isLoading }
      submitLabel="Save"
      title="Profile"
      onSubmit={ handleSubmit }
    >
      <FFInput
        id="id"
        label="ID"
        value={ id }
        disabled
      />
      <FFInput
        id="username"
        label="Username"
        value={ username }
        onChange={ handleUsernameChange }
      />
      <FFInput
        id="email"
        label="Email"
        value={ email }
        onChange={ handleEmailChange }
      />
      <FFCheckbox
        id="is-superuser"
        label="Superuser"
        checked={ isSuperuser }
        disabled
      />
      <FFInput
        id="created-at"
        label="Created At"
        value={ createdAt }
        disabled
      />
      <FFInput
        id="last-login"
        label="Last login"
        value={ lastLogin }
        disabled
      />
      <FFInput
        id="new-password"
        label="New password"
        type="password"
        value={ newPassword }
        onChange={ handleNewPasswordChange }
      />
      <FFInput
        id="repeat-new-password"
        label="Repeat new password"
        type="password"
        value={ repeatNewPassword }
        onChange={ handleRepeatNewPasswordChange }
      />
      <hr />
      <FFInput
        id="old-password"
        label="Old password"
        type="password"
        value={ oldPassword }
        onChange={ handleOldPasswordChange }
      />
    </FFForm>
  );
}

export default Profile;
