import React from "react";
import "./FFModal.css";

interface FFMModalInterface {
  children: React.ReactNode;
  title: string;
  visible: boolean;
  onClose: () => void;
}

function FFModal({
  children,
  title,
  visible,
  onClose
}: FFMModalInterface) {
  return <div className={`ff-modal ${ visible ? "visible" : "" }`}>
    <div className="ff-modal-container">
      <header>
        <h3>{title}</h3>
        <button className="close-header" onClick={onClose}>Close</button>
      </header>
      {children}
    </div>
  </div>;
}

export default FFModal;
