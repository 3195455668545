import React from "react";
import "./AdminsUpdate.css";

import { useNavigate, useParams } from "react-router-dom";

import FFCheckbox from "components/primitive/ff-checkbox/FFCheckbox";
import FFForm from "components/primitive/ff-form/FFForm";
import FFInput from "components/primitive/ff-input/FFInput";
import FFLink from "components/primitive/ff-link/FFLink";

import ApiService from "utils/api.service";
import { AdminModelUpdate } from "utils/data-structures";

interface AdminsUpdateInterface {
  apiService: ApiService;
}

function AdminsUpdate({ apiService }: AdminsUpdateInterface ) {
  const { elementId } = useParams();
  const navigate = useNavigate();

  const [ feedback, setFeedback ] = React.useState("");
  const [ isLoading, setIsLoading ] = React.useState(false);

  const [ username, setUsername ] = React.useState("");
  const [ password, setPassword ] = React.useState("");
  const [ email, setEmail ] = React.useState("");
  const [ isSuperuser, setIsSuperuser ] = React.useState(false);

  const handleUsernameChange = (ev: React.FormEvent<HTMLInputElement>) => setUsername(ev.currentTarget.value);
  const handlePasswordChange = (ev: React.FormEvent<HTMLInputElement>) => setPassword(ev.currentTarget.value);
  const handleEmailChange = (ev: React.FormEvent<HTMLInputElement>) => setEmail(ev.currentTarget.value);
  const handleIsSuperuserChange = (checked: boolean) => setIsSuperuser(checked);

  const handleCancel = () => {
    navigate("/app/admins");
  }

  const handleSubmit = () => {
    if (!elementId || isNaN(parseInt(elementId, 10)) ) {
      setFeedback("Admin ID is missing. What are you doing?");
      return;
    }

    if (!username || username.trim().length < 1) {
      setFeedback("Admin username is required.");
      return;
    }

    if (!email || email.trim().length < 1) {
      setFeedback("Admin email is required.");
      return;
    }

    if (password && password.trim().length < 1) {
      setFeedback("Password must contain characters.");
      return;
    }

    setIsLoading(true);
    setFeedback("");

    const payload: AdminModelUpdate = { id: parseInt(elementId, 10), username, email, isSuperuser };

    if (password) {
      payload.password = password;
    }

    apiService.updateAdmin(payload)
      .then(() => {
        setIsLoading(false);
        setFeedback("Update successful.");
      })
      .catch((error: any) => {
        setIsLoading(false);
        setFeedback(error);
      });
  }

  const title = <><FFLink href="/app/admins">Admins</FFLink> / Update</>;

  React.useEffect(() => {
    if (!elementId || isNaN(parseInt(elementId, 10))) {
      setFeedback("Admin ID is missing. Cannot proceed.");
      return;
    }

    const id = parseInt(elementId, 10);

    apiService.getAdmin(id)
      .then(admin => {
        setFeedback("");

        setUsername(admin.username);
        setPassword("");
        setEmail(admin.email);
        setIsSuperuser(admin.isSuperuser);
      })
      .catch((error: any) => {
        setFeedback(error);
      });
  }, [ apiService, elementId ]);

  return (
    <FFForm
      feedback={ feedback }
      hasCancel={ true }
      isLoading={ isLoading }
      submitLabel="Update"
      title={ title }
      onCancel={ handleCancel }
      onSubmit={ handleSubmit }
    >
      <FFInput
        id="username"
        label="Username"
        value={ username }
        onChange={ handleUsernameChange }
      />
      <FFInput
        id="password"
        label="Password"
        value={ password }
        onChange={ handlePasswordChange }
      />
      <FFInput
        id="email"
        label="Email"
        value={ email }
        onChange={ handleEmailChange }
      />
      <FFCheckbox
        id="is-superuser"
        label="Is superuser"
        checked={ isSuperuser }
        onChange={ handleIsSuperuserChange }
      />
    </FFForm>
  );
}

export default AdminsUpdate;
