import React from "react";
import "./DomainsCreate.css";

import { useNavigate } from "react-router-dom";

import FFForm from "components/primitive/ff-form/FFForm";
import FFInput from "components/primitive/ff-input/FFInput";
import FFLink from "components/primitive/ff-link/FFLink";

import ApiService from "utils/api.service";

interface DomainsCreateInterface {
  apiService: ApiService;
}

function DomainsCreate({ apiService }: DomainsCreateInterface ) {
  const navigate = useNavigate();

  const [ feedback, setFeedback ] = React.useState("");
  const [ isLoading, setIsLoading ] = React.useState(false);

  const [ name, setName ] = React.useState("");
  const [ color, setColor ] = React.useState("");

  const handleNameChange = (ev: React.FormEvent<HTMLInputElement>) => setName(ev.currentTarget.value);
  const handleColorChange = (ev: React.FormEvent<HTMLInputElement>) => setColor(ev.currentTarget.value);

  const handleCancel = () => {
    navigate("/app/domains");
  }

  const handleSubmit = () => {
    if (!name || name.trim().length < 1) {
      setFeedback("Enter name of the new domain.");
      return;
    }

    if (!color || color.trim().length < 1) {
      setFeedback("Enter color of the new domain.");
      return;
    }

    setIsLoading(true);
    setFeedback("");

    const payload = { name, color };

    apiService.createDomain(payload)
      .then(() => {
        navigate("/app/domains");
      })
      .catch((error: any) => {
        setIsLoading(false);
        setFeedback(error);
      });
  }

  const title = <><FFLink href="/app/domains">Domains</FFLink> / Create</>;

  return (
    <FFForm
      feedback={ feedback }
      hasCancel={ true }
      isLoading={ isLoading }
      submitLabel="Create"
      title={ title }
      onCancel={ handleCancel }
      onSubmit={ handleSubmit }
    >
      <FFInput
        id="name"
        label="Name"
        value={ name }
        onChange={ handleNameChange }
      />
      <FFInput
        id="color"
        label="Color"
        value={ color }
        onChange={ handleColorChange }
      />
    </FFForm>
  );
}

export default DomainsCreate;
