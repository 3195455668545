import React from "react";
import "./FFCheckbox.css";

interface FFCheckboxInterface {
  id: string;
  disabled?: boolean;
  label: string;
  checked: boolean;
  onChange?: (checked: boolean) => void;
}

function FFCheckbox({
  id,
  disabled = false,
  label,
  checked,
  onChange
}: FFCheckboxInterface) {
  const handleChange = (ev: React.FormEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(ev.currentTarget.checked);
    }
  }

  const classNames = [ "ff-checkbox" ];

  if (disabled) {
    classNames.push("disabled");
  }

  return (
    <label className={ classNames.join(" ") } htmlFor={ id }>
      <input
        disabled={ disabled }
        id={ id }
        type="checkbox"
        checked={ checked }
        onChange={ handleChange }
      />
      <span className="ff-checkbox-check"></span>
      <span className="ff-checkbox-label">{ label }</span>
    </label>
  );
}

export default FFCheckbox;
