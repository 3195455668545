import {
  KanbanTaskDomain,
  KanbanTask,
  KanbanTaskModel,
  ProfileModel
} from "utils/data-structures";

export function isProperKanbanTaskModel(data: { [ key: string ]: any }): "OK" | string {
  if (!data["title"] || typeof data["title"] !== "string" || data["title"].length < 3) {
    return "Task title is missing or inappropriate.";
  }

  if (!data["shortTitle"] || typeof data["shortTitle"] !== "string" || data["shortTitle"].length < 3) {
    if (data["shortTitle"].length > 16) {
      return "Short title is not short enough.";
    }

    if (data["shortTitle"].length < 3) {
      return "Short title is too short.";
    }

    return "Short title is missing or inappropriate.";
  }

  if (!data["shortTitle"] || typeof data["shortTitle"] !== "string") {
    return "There is something wrong with task description.";
  }

  if (!data["priority"] || typeof data["priority"] !== "number" || data["priority"] < 0) {
    return "Task priority must be a number greater than 0.";
  }

  return "OK";
}

export function getPrettyDate(timestamp: string | undefined): string {
  if (typeof timestamp === "undefined") {
    return "";
  }

  const date = new Date(timestamp);

  return date.toLocaleString();
}

export function kanbanTaskModelsToBoardFormat(models: Array<KanbanTaskModel>, domains: Array<KanbanTaskDomain>, assignees: Array<ProfileModel>): Array<KanbanTask> {
  return models.map(model => kanbanTaskModelToBoardFormat(model, domains, assignees));
}

export function kanbanTaskModelToBoardFormat(model: KanbanTaskModel, domains: Array<KanbanTaskDomain>, assignees: Array<ProfileModel>): KanbanTask {
  if (!model.id) {
    throw new Error("Missing ID property in KanbanTaskModel");
  }

  const assignee = typeof model.assigneeId === "number" ? assignees.find(x => x.id === model.assigneeId) : null;

  const kanbanTask: KanbanTask = {
    id: model.id,
    title: model.title,
    shortTitle: model.shortTitle,
    state: model.state,
    type: model.type,
    priority: model.priority,
    domain: typeof model.domainId === "number" ? domains.find(x => x.id === model.domainId) || null : null,
    parent: typeof model.parentId === "number" ? model.parentId : null,
    assignee: assignee && assignee.username ? assignee.username : null
  }

  if (kanbanTask.type === "Roadmap") {
    // Link that is relative to the base, e.g. "/app/roadmap"
    kanbanTask.link = model.id.toString();
  }

  if (kanbanTask.type === "Epic") {
    // Link that is relative to the roadmap item
    kanbanTask.link = "epic/" + model.id.toString();
  }

  return kanbanTask;
}
