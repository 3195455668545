export interface GetAllResponse<T> {
  total: number;
  data: Array<T>
}

export interface FFSelectOptionInterface {
  value: string;
  name: string;
}

export interface UserAuthInformation {
  tokenAccess: string;
  id: number;
  username: string;
  isSuperuser: boolean;
}

export type KanbanTaskState = "To Define" | "To Do" | "In Progress" | "In Review" | "Done";

export const KanbanTaskStateOptions = [
  { name: "To Define", value: "To Define" },
  { name: "To Do", value: "To Do" },
  { name: "In Progress", value: "In Progress" },
  { name: "In Review", value: "In Review" },
  { name: "Done", value: "Done" }
];

export type KanbanTaskType = "Roadmap" | "Epic" | "Story";

export const KanbanTaskTypeOptions = [
  { name: "Roadmap", value: "Roadmap" },
  { name: "Epic", value: "Epic" },
  { name: "Story", value: "Story" }
];

export type KanbanTaskDomain = {
  id: number;
  name: string;
  color: string;
}

export type KanbanTaskDomainCreate = {
  name: string;
  color: string;
}

export interface KanbanTask {
  id: number;
  title: string;
  shortTitle: string;
  state: KanbanTaskState;
  type: KanbanTaskType;
  priority: number;
  domain: null | KanbanTaskDomain;
  parent: null | number;
  assignee: null | string;
  link?: string;
}

export interface KanbanTaskModel {
  id?: number;
  title: string;
  shortTitle: string;
  description: string;
  state: KanbanTaskState;
  type: KanbanTaskType;
  priority: number;
  domainId: null | number;
  parentId: null | number;
  authorId: number;
  assigneeId: null | number;
  createdAt?: string;
  updatedAt?: string;
}

export type ProfileModel = {
  id: number;
  username: string;
  email: string;
  isSuperuser: boolean;
  createdAt: string;
  lastLogin: string;
}

export interface ProfileModelUpdate {
  currentPassword: string;
  username: string;
  email: string;
  newPassword?: string;
}

export interface AdminModelCreate {
  username: string;
  password: string;
  email: string;
  isSuperuser: boolean;
}

export interface AdminModelUpdate {
  id: number;
  username?: string;
  password?: string;
  email?: string;
  isSuperuser?: boolean;
}
