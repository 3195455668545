import React from "react";
import "./FFModalCreateTask.css";

import {
  FFSelectOptionInterface,
  KanbanTaskDomain,
  KanbanTaskModel,
  KanbanTaskState,
  KanbanTaskStateOptions,
  KanbanTaskType,
  KanbanTaskTypeOptions,
  ProfileModel
} from "utils/data-structures";

import FFButton from "components/primitive/ff-button/FFButton";
import FFInput from "components/primitive/ff-input/FFInput";
import FFInputTextArea from "components/primitive/ff-input-textarea/FFInputTextArea";
import FFLoading from "components/primitive/ff-loading/FFLoading";
import FFMessage from "components/primitive/ff-message/FFMessage";
import FFModal from "components/smart/ff-modal/FFModal";
import FFSelect from "components/primitive/ff-select/FFSelect";

interface FFMModalCreateTaskInterface {
  feedback: string;
  isLoading: boolean;
  modalTitle: string;
  task: KanbanTaskModel;
  visible: boolean;
  domains?: Array<KanbanTaskDomain>;
  assignees?: Array<ProfileModel>;
  prettyParent?: string;
  onClose: () => void;
  onSave: (data: KanbanTaskModel) => void;
}

function FFModalCreateTask({
  feedback,
  isLoading,
  modalTitle,
  task,
  visible,
  domains = [],
  assignees = [],
  prettyParent = "",
  onClose,
  onSave
}: FFMModalCreateTaskInterface) {
  // Invisible and non-editable fields
  const [ taskAuthorId ] = React.useState(task.authorId);
  const [ taskParentId ] = React.useState(task.parentId);
  const [ taskType, setTaskType ] = React.useState(task.type);

  // Editable fields
  const [ taskTitle, setTaskTitle ] = React.useState(task.title);
  const [ shortTaskTitle, setShortTaskTitle ] = React.useState(task.shortTitle);
  const [ taskDescription, setTaskDescription ] = React.useState(task.description);
  const [ taskState, setTaskState ] = React.useState(task.state);
  const [ taskPriority, setTaskPriority ] = React.useState<number>(1);
  const [ taskDomainId, setTaskDomainId ] = React.useState((task.domainId || "---").toString());
  const [ taskAssigneeId, setTaskAssigneeId ] = React.useState((task.assigneeId || "---").toString());

  const [ defaultDomainName, setDefaultDomainName ] = React.useState("");
  const [ domainOptions, setDomainOptions ] = React.useState<Array<FFSelectOptionInterface>>([]);

  const [ defaultAssigneeName, setDefaultAssigneeName ] = React.useState("");
  const [ assigneeOptions, setAssigneeOptions ] = React.useState<Array<FFSelectOptionInterface>>([]);

  const handleSubmit = (ev: React.MouseEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const domainId = parseInt(taskDomainId, 10);
    const assigneeId = parseInt(taskAssigneeId, 10);

    const data = {
      title: taskTitle,
      shortTitle: shortTaskTitle,
      description: taskDescription,
      state: taskState,
      type: taskType,
      priority: taskPriority,
      domainId: isNaN(domainId) ? null : domainId,
      parentId: taskParentId,
      authorId: taskAuthorId,
      assigneeId: isNaN(assigneeId) ? null : assigneeId
    }

    onSave(data);
  }

  const handleTaskTitleChange = (ev: React.FormEvent<HTMLInputElement>) => setTaskTitle(ev.currentTarget.value);
  const handleShortTaskTitleChange = (ev: React.FormEvent<HTMLInputElement>) => setShortTaskTitle(ev.currentTarget.value);
  const handleTaskDescriptionChange = (ev: React.FormEvent<HTMLTextAreaElement>) => setTaskDescription(ev.currentTarget.value);
  const handleTaskStateChange = (value: string) => setTaskState(value as KanbanTaskState);
  const handleTaskTypeChange = (value: string) => setTaskType(value as KanbanTaskType);
  const handleTaskPriorityChange = (ev: React.FormEvent<HTMLInputElement>) => setTaskPriority(parseInt(ev.currentTarget.value));
  const handleTaskDomainChange = (value: string) => setTaskDomainId(value);
  const handleTaskAssigneeChange = (value: string) => setTaskAssigneeId(value);

  const isDomainDisabled = taskType === "Roadmap";

  const message = feedback ? <FFMessage>{ feedback }</FFMessage> : (<></>);
  const footer = isLoading ? <FFLoading /> : <FFButton type="submit">Save</FFButton>;

  React.useEffect(() => {
    if (domains.length < 1) {
      return;
    }

    const intDomainOptions = [ { name: "None", value: "---" } ];

    if (domains) {
      domains.forEach(el => intDomainOptions.push({ name: el.name, value: el.id.toString() }));
    }

    setDomainOptions(intDomainOptions);
  }, [ domains ]);

  React.useEffect(() => {
    if (taskDomainId) {
      const selectedDomain = domainOptions.find(el => el.value === taskDomainId);
      selectedDomain && setDefaultDomainName(selectedDomain.name);
    }
  }, [ taskDomainId, domainOptions ]);

  React.useEffect(() => {
    if (assignees.length < 1) {
      return;
    }

    const intAssigneeOptions = [ { name: "None", value: "---" } ];

    if (assignees) {
      assignees.forEach(el => intAssigneeOptions.push({ name: el.username, value: el.id.toString() }));
    }

    setAssigneeOptions(intAssigneeOptions);
  }, [ assignees ]);

  React.useEffect(() => {
    if (taskAssigneeId) {
      const selectedAssignee = assigneeOptions.find(el => el.value === taskAssigneeId);
      selectedAssignee && setDefaultAssigneeName(selectedAssignee.name);
    }
  }, [ taskAssigneeId, assigneeOptions ]);

  return <FFModal
    visible={ visible }
    title={ modalTitle }
    onClose={ () => onClose() }
  >
    <form onSubmit={ handleSubmit }>
      <FFInput
        id="title"
        label="Title"
        value={ taskTitle }
        onChange={ handleTaskTitleChange } />
      <FFInput
        id="short_title"
        label="Short title"
        value={ shortTaskTitle }
        onChange={ handleShortTaskTitleChange } />
      <FFInputTextArea
        id="description"
        label="Description"
        value={ taskDescription }
        onChange={ handleTaskDescriptionChange } />
      <FFSelect
        id="state"
        label="State"
        options={ KanbanTaskStateOptions }
        value={ taskState }
        onChange={ handleTaskStateChange } />
      <FFSelect
        id="type"
        disabled={ true }
        label="Type"
        options={ KanbanTaskTypeOptions }
        value={ taskType }
        onChange={ handleTaskTypeChange } />
      <FFInput
        id="priority"
        label="Priority"
        type="number"
        value={ taskPriority.toString() }
        onChange={ handleTaskPriorityChange } />
      <FFSelect
        id="domain"
        label="Domain"
        options={ domainOptions }
        value={ taskDomainId }
        defaultName={ defaultDomainName }
        disabled={ isDomainDisabled }
        onChange={ handleTaskDomainChange } />
      <FFSelect
        id="assignee"
        label="Assignee"
        options={ assigneeOptions }
        value={ taskAssigneeId }
        defaultName={ defaultAssigneeName }
        onChange={ handleTaskAssigneeChange } />
      <FFInput
        id="parent"
        label="Parent"
        value={ prettyParent }
        disabled={ true } />
      { message }
      <footer>
        { footer }
      </footer>
    </form>
  </FFModal>;
}

export default FFModalCreateTask;
