import React from "react";
import "./FFInputTextArea.css";

interface FFInputTextAreaInterface {
  id: string;
  label: string;
  value?: string;
  onChange?: (ev: React.FormEvent<HTMLTextAreaElement>) => void;
}

function FFInputTextArea({ id, label, value = "", onChange }: FFInputTextAreaInterface) {
  const handleChange = (ev: React.FormEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(ev);
    }
  }

  return (
    <div className="ff-input-textarea">
      <label htmlFor={ id }>{ label }</label>
      <textarea
        id={ id }
        rows={ 12 }
        value={ value }
        onChange={ handleChange }
      />
    </div>
  );
}

export default FFInputTextArea;
