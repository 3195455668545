import React from "react";
import "./NotFound.css";

import FFLink from "components/primitive/ff-link/FFLink";
import FFTitle from "components/primitive/ff-title/FFTitle";

function NotFound() {
  return (<div className="not-found">
    <FFTitle>Page not found</FFTitle>
    <FFLink href="/app">Home</FFLink>
  </div>);
}

export default NotFound;
