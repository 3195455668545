import React from "react";
import "./Sidebar.css";
import { NavLink } from "react-router-dom";

interface SidebarInterace {
  isVisible: boolean;
}

function Sidebar({ isVisible }: SidebarInterace) {
  const classNames = [ "sidebar" ];

  if (isVisible) {
    classNames.push("visible");
  }

  return (
    <div className={ classNames.join(" ") }>
      <nav>
        <ul>
          <li>
            <NavLink
              end
              to=""
              className={({ isActive }) => (isActive ? "is-active" : undefined)}
            >
              Home
            </NavLink>
          </li>
          <li>---</li>
          <li>
            <NavLink
              to="roadmap"
              className={({ isActive }) => (isActive ? "is-active" : undefined)}
            >
              Roadmap
            </NavLink>
          </li>
          <li>
            <NavLink
              to="domains"
              className={({ isActive }) => (isActive ? "is-active" : undefined)}
            >
              Domains
            </NavLink>
          </li>
          <li>---</li>
          <li>
            <NavLink
              to="admins"
              className={({ isActive }) => (isActive ? "is-active" : undefined)}
            >
              Admins
            </NavLink>
          </li>
          <li>
            <NavLink
              to="profile"
              className={({ isActive }) => (isActive ? "is-active" : undefined)}
            >
              Profile
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
