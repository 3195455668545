import React from "react";
import "./FFSmartTable.css";

import FFButton from "components/primitive/ff-button/FFButton";
import FFLoading from "components/primitive/ff-loading/FFLoading";
import FFTitle from "components/primitive/ff-title/FFTitle";

import { defaultPageSize } from "configuration";

interface FFSmartTableOptions {
  shouldCreate?: boolean;
  shouldDelete?: boolean;
  shouldUpdate?: boolean;
  keyProp: string;
}

interface FFSmartTableColumn {
  name: string;
  key: string;
  width: number;
}

type FFSmartTableItem = { [key: string]: string | number | boolean };

interface FFSmartTableInterface {
  tableName: string;
  options: FFSmartTableOptions;
  columns: Array<FFSmartTableColumn>;
  data?: Array<FFSmartTableItem>;
  total: number;
  pageSize?: number;
  isLoading?: boolean;
  onCreate?: () => void;
  onUpdate?: (id: string) => void;
  onDelete?: (id: string) => void;
  onNextPage?: (offset: number, limit: number) => void;
  onPrevPage?: (offset: number, limit: number) => void;
  onFirstPage?: (offset: number, limit: number) => void;
  onLastPage?: (offset: number, limit: number) => void;
}

const _ACTIONS_WIDTH = 20;

function getTableHeader(columns: Array<FFSmartTableColumn>) {
  const resizeFactor = 1 - ( _ACTIONS_WIDTH / 100);
  const tableHeader = [];

  for (const column of columns) {
    const width = (column.width * resizeFactor).toString() + "%";
    const key = "th-" + column.key;
    tableHeader.push(<th key={key} style={{width: width}}>{column.name}</th>);
  }

  tableHeader.push(<th key="th-actions" style={{width: _ACTIONS_WIDTH + "%"}}>Actions</th>);

  return tableHeader;
}

function getTableData(
  keyProp: string,
  columns: Array<FFSmartTableColumn>,
  data: Array<FFSmartTableItem>,
  shouldUpdate: boolean,
  shouldDelete: boolean,
  onUpdate: (id: string) => void,
  onDelete: (id: string) => void
) {
  if (!data || data.length === 0) {
    return (<tr>
      <td colSpan={columns.length + 1} className="no-data">There's nothing here.</td>
    </tr>);
  }

  const tableData = [];

  for (const item of data) {
    const keyBase = item[keyProp].toString();
    const cells = [];

    for (const cell of columns) {
      const cellKey = "cell-" + cell.key + "-" + keyBase;
      const displayValue = typeof item[cell.key] === "boolean" ? JSON.stringify(item[cell.key]) : item[cell.key];
      cells.push(<td key={cellKey}>{ displayValue }</td>);
    }

    const actionCell = [
      shouldUpdate ?
        <FFButton
          onClick={(ev: React.FormEvent<HTMLButtonElement>) => {
            ev.preventDefault();
            onUpdate(item.id.toString());
          }}
        >Update</FFButton>
      :
        (<></>),
      shouldDelete ?
        <FFButton
          onClick={(ev: React.FormEvent<HTMLButtonElement>) => {
            ev.preventDefault();
            onDelete(item.id.toString());
          }}
        >Delete</FFButton>
      :
        (<></>)
    ];

    cells.push(<td key={"cell-action-" + keyBase} className="actions">{actionCell[0]}{actionCell[1]}</td>);

    tableData.push(<tr key={"row-" + keyBase}>{cells}</tr>);
  }

  return tableData;
}

function getTableLoading(columns: Array<FFSmartTableColumn>) {
  return (<tr>
    <td colSpan={columns.length + 1} className="is-loading"><FFLoading /></td>
  </tr>);
}

function FFSmartTable({
  tableName,
  options: {
    shouldCreate = false,
    shouldDelete = false,
    shouldUpdate = false,
    keyProp
  },
  columns,
  data = [],
  total,
  pageSize = defaultPageSize,
  isLoading = false,
  onCreate,
  onUpdate,
  onDelete,
  onNextPage,
  onPrevPage,
  onFirstPage,
  onLastPage
}: FFSmartTableInterface) {
  const classNames = ["ff-smart-table-container"];
  const createAction = shouldCreate ?
    <FFButton
      onClick={(ev: React.FormEvent<HTMLButtonElement>) => {
        ev.preventDefault();
        onCreate && onCreate();
      }}
    >Create</FFButton>
  :
    (<></>);

  if (shouldCreate) {
    classNames.push("has-create");
  }

  const tableHeader = getTableHeader(columns);
  const tableData = (() => {
    if (isLoading) {
      return getTableLoading(columns);
    }

    return getTableData(
      keyProp,
      columns,
      data,
      shouldUpdate,
      shouldDelete,
      (id: string) => onUpdate && onUpdate(id),
      (id: string) => onDelete && onDelete(id)
    );
  })();

  const [ currentPage, setCurrentPage ] = React.useState(1);
  const maxPage = Math.ceil(total / pageSize);

  const handleNextPage = () => {
    if (currentPage === maxPage || !onNextPage) {
      return;
    }

    onNextPage((currentPage) * pageSize, pageSize);
    setCurrentPage(currentPage + 1);
  }

  const handlePrevPage = () => {
    if (currentPage === 1 || !onPrevPage) {
      return;
    }

    onPrevPage((currentPage - 2) * pageSize, pageSize);
    setCurrentPage(currentPage - 1);
  }

  const handleFirstPage = () => {
    if (currentPage === 1 || !onFirstPage) {
      return;
    }

    setCurrentPage(1);
    onFirstPage(0, pageSize);
  }

  const handleLastPage = () => {
    if (currentPage === maxPage || !onLastPage) {
      return;
    }

    setCurrentPage(maxPage);
    onLastPage((maxPage - 1) * pageSize, pageSize);
  }

  return <div className={ classNames.join(" ") }>
    <FFTitle>{tableName}</FFTitle>
    {createAction}
    <table className="ff-smart-table">
      <thead>
        <tr>{tableHeader}</tr>
      </thead>
      <tbody>{tableData}</tbody>
      <tfoot>
        <tr>
          <td colSpan={columns.length + 1}>
            <FFButton onClick={handleFirstPage}>&lt;&lt;</FFButton>
            <FFButton onClick={handlePrevPage}>&lt;</FFButton>
            <span>{currentPage} / {maxPage}</span>
            <FFButton onClick={handleNextPage}>&gt;</FFButton>
            <FFButton onClick={handleLastPage}>&gt;&gt;</FFButton>
            <label className="tfoot-total">Count: {total}</label>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>;
}

export default FFSmartTable;
