import React from "react";
import "./AdminsDelete.css";

import { useNavigate, useParams } from "react-router-dom";

import FFForm from "components/primitive/ff-form/FFForm";
import FFLink from "components/primitive/ff-link/FFLink";

import ApiService from "utils/api.service";

interface AdminsDeleteInterface {
  apiService: ApiService;
}

function AdminsDelete({ apiService }: AdminsDeleteInterface ) {
  const { elementId } = useParams();
  const navigate = useNavigate();

  const [ feedback, setFeedback ] = React.useState("");
  const [ isLoading, setIsLoading ] = React.useState(false);

  const [ username, setUsername ] = React.useState("");

  const handleCancel = () => {
    navigate("/app/admins");
  }

  const handleSubmit = () => {
    if (!elementId || isNaN(parseInt(elementId, 10)) ) {
      setFeedback("Admin ID is missing. What are you doing?");
      return;
    }

    setIsLoading(true);
    setFeedback("");

    apiService.deleteAdmin(parseInt(elementId, 10))
      .then(() => {
        navigate("/app/admins");
      })
      .catch((error: any) => {
        setIsLoading(false);
        setFeedback(error);
      });
  }

  const title = <><FFLink href="/app/admins">Admins</FFLink> / Delete</>;

  React.useEffect(() => {
    if (!elementId || isNaN(parseInt(elementId, 10))) {
      setFeedback("Admin ID is missing. Cannot proceed.");
      return;
    }

    const id = parseInt(elementId, 10);

    apiService.getAdmin(id)
      .then(admin => {
        setFeedback("");

        setUsername(admin.username);
      })
      .catch((error: any) => {
        setFeedback(error);
      });
  }, [ apiService, elementId ]);

  return (
    <FFForm
      feedback={ feedback }
      hasCancel={ true }
      isLoading={ isLoading }
      submitLabel="Delete"
      title={ title }
      onCancel={ handleCancel }
      onSubmit={ handleSubmit }
    >
      <p>Are you sure you want to delete admin <strong>"{ username }"</strong>?</p>
    </FFForm>
  );
}

export default AdminsDelete;
