import React from "react";
import "./FFKanbanTask.css";

import { Link } from "react-router-dom";

import { KanbanTask } from "utils/data-structures";

interface FFKanbanTaskInterface {
  task: KanbanTask;
  onTaskClick: (id: number) => void;
}

function FFKanbanTask({
  task,
  onTaskClick
}: FFKanbanTaskInterface) {
  const handleClick = (ev: React.MouseEvent<HTMLElement>) => {
    onTaskClick(task.id);
  }

  const renderLabel =
    !task.domain || task.type === "Roadmap" ?
    <></> :
    <label><span style={{ backgroundColor: task.domain.color }}></span>{ task.domain.name }</label>;

  const renderLink =
    !task.link || task.type === "Story" ?
    <></> :
    <Link className="link" to={ task.link }>Board &gt;</Link>

  return <article className="ff-kanban-task">
    <span className="ff-kanban-task-priority">{ task.priority }</span>
    <p className="ff-kanban-task-assignee">{ task.assignee || <em>---</em> }</p>
    <h4 onClick={ handleClick }>{ task.title }</h4>
    { renderLabel }
    { renderLink }
  </article>;
}

export default FFKanbanTask;
